import styled from "styled-components"
import { device } from "breakpoints"
import { DoublePanel } from "../DoublePanel"
import { HeaderText } from "../HomePage/style"
import { MainColumn } from "components/MainColumn"

export const NSWHomePageContainer = styled.div``

export const NSWHeaderText = styled(HeaderText)`
  font-size: 16px;
  line-height: 133%;
  margin-bottom: 40px;
  width: 80%;
  @media ${device.desktop} {
    font-size: 24px;
    max-width: 755px;
  }
`

export const NSWDoublePanel = styled(DoublePanel)`
  margin-top: -15px;

  @media ${device.tablet} {
    margin-top: 0;
  }
`

export const FooterTextContainer = styled(MainColumn)`
  margin-top: 40px;
  margin-bottom: 15px;
  @media ${device.desktop} {
    margin-top: 50px;
    margin-bottom: 0;
  }
`
export const FooterText = styled.div`
  p {
    width: 100%;
    font-size: 16px;
    line-height: 160%;
    @media ${device.tablet} {
      font-size: 18px;
    }
  }

  strong {
    font-weight: 500;
  }
`
