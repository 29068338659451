import React from "react"

import {
  NSWHeaderText,
  DesktopNSWHeaderText,
  NSWDoublePanel,
  FooterTextContainer,
  FooterText,
} from "./style"
import { HomePageContainer } from "components/HomePage/style"
import { MainColumn } from "components/MainColumn"
import { Link } from "gatsby"
import { ReactComponent as NSWillustration } from "images/icons/illustration-nsw-v01.svg"
import { ReactComponent as Arrow } from "images/icons/arrow.svg"
import { useStaticQuery, graphql } from "gatsby"

import {
  LeftPanelContent,
  RightPanelContent,
  PanelTitle,
  TextWrapper,
  PanelTextWYSY,
} from "components/DoublePanel/style"
const NSWHomePage = () => {
  const data = useStaticQuery(graphql`
    {
      wp {
        finefixerOptions {
          homeOptions {
            headerStatement
            mainpanel {
              heading
              description
            }
            allpanel {
              heading
              description
            }
            questionpanel {
              heading
              description
            }
            needToSpeak
            providedBy
          }
        }
      }
    }
  `)

  const pageData = data.wp.finefixerOptions.homeOptions
  console.log("pageData:", pageData.needToSpeak)

  const leftContent = (
    <LeftPanelContent>
      {/* <Link to="/nsw"> */}
      <PanelTitle hideMobile noLine>
        <span>{pageData.mainpanel.heading}</span>
      </PanelTitle>
      {/* </Link> */}
      <TextWrapper>
        <p className="hideMobile">{pageData.mainpanel.description} </p>

        <NSWillustration className="cover-svg nsw-illustration nsw-landing" />
        <PanelTextWYSY
          dangerouslySetInnerHTML={{
            __html: pageData.providedBy,
          }}
        />
      </TextWrapper>
    </LeftPanelContent>
  )

  const rightContent = (
    <>
      <RightPanelContent half>
        <Link to="/questions">
          <PanelTitle>
            <span>{pageData.questionpanel.heading}</span>
            <Arrow />
          </PanelTitle>
        </Link>
        <TextWrapper>
          <p>{pageData.questionpanel.description}</p>
        </TextWrapper>
      </RightPanelContent>

      <RightPanelContent half>
        <Link to="/all-options">
          <PanelTitle>
            <span>{pageData.allpanel.heading}</span>
            <Arrow />
          </PanelTitle>
        </Link>
        <TextWrapper>
          <p>{pageData.allpanel.description}</p>
        </TextWrapper>
      </RightPanelContent>
    </>
  )
  return (
    <HomePageContainer>
      <MainColumn>
        <NSWHeaderText>{pageData.headerStatement}</NSWHeaderText>
      </MainColumn>
      <NSWDoublePanel
        leftColor="#C0DFF7"
        rightColor="none"
        leftContent={leftContent}
        rightContent={rightContent}
      />

      <FooterTextContainer>
        <FooterText
          dangerouslySetInnerHTML={{
            __html: pageData.needToSpeak,
          }}
        />
      </FooterTextContainer>
    </HomePageContainer>
  )
}

export default NSWHomePage
