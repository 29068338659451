import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { NSWHomePage } from "../components/NSWHomePage"

const IndexPage = () => {
  return (
    <Layout nsw>
      <SEO />
      <NSWHomePage />
    </Layout>
  )
}

export default IndexPage
